import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import WrapperStyle from './components/WrapperStyle';
import Header from './components/Header'
import Routes from './routes';
import Footer from './components/Footer'

import store from './store'

function App() {
    
    return (        
        <Provider store={store}>            
            <BrowserRouter>
                <WrapperStyle />
                <Header />                
                <Routes />                
                <Footer />
            </BrowserRouter>
        </Provider>             
    );
}

export default App;
